// TODO: move to .env dev file

const API_ENDPOINT = "https://prd-api-madam-nl.groupm.com";

export const serviceConfig = {
  resourceId: "ad59c744-6d1b-4e22-81b3-3cdae3bc7046",
  resourceBaseAddress: API_ENDPOINT,
};

export const BASE_URL = `api/agency/:agencyId`;

export const GET_INIT = "init";
